import React, { useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';


const Profile = () => {
    const { username } = useParams();

    useEffect(() => {
        window.open('https://beemarks.app/' + username, "_self")
    }, [username])
    return (
        <div>Routing beemarks profile...</div>
    )
}

export default Profile