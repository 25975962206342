import React from 'react'
import Header from '../components/Header'
import Features from '../components/Features'
import Footer from '../components/Footer'
const LandingPage = () => {
    return <>
        <div className='bg-[#F9F9FF]'>
            <div className='absolute inset-0 bg-gradient-to-b  from-bgcolor to-white font-outfit'>
                <div className='py-8 px-7 md:px-40'>
                    <Header />
                    <Features />
                </div>
                <Footer />
            </div>

        </div>
    </>

}

export default LandingPage