import React from 'react'
import arcBrowser from "../assets/images/browsers/arc_colorless.png";
import chromeBrowser from "../assets/images/browsers/chrome_colorless.png";
import edgeBrowser from "../assets/images/browsers/edge_colorless.png";
import operaBrowser from "../assets/images/browsers/opera_colorless.png";
import duckDuckGoBrowser from "../assets/images/browsers/duck_duck_go_colorless.png";
import puffinBrowser from "../assets/images/browsers/puffin_colorless.png";
import ucBrowser from "../assets/images/browsers/uc_colorless.png";
import thumbnail from "../assets/images/other/thumbnail_with_play.png";
import secondSticker from "../assets/images/other/save_bookmark.png"
import firstSticker from "../assets/images/other/share_bookmark.png"
import cat from "../assets/images/other/cat.png"
import blueBoxContent from "../assets/images/other/blue_box_content.png"
import redBoxContent from "../assets/images/other/red_box_content.png"
import reviewBackgroundImage from "../assets/images/other/review_background_image.png";
import whiteArrow from "../assets/images/vector/white_arrow.svg";
import FAQ from './FAQ';
import openApp from '../utils/openApp';

const openExample = () => {
    window.open('https://beemarks.app/beratkurt', '_blank');
}

const Features = () => {
    return (
        <div className='flex flex-col items-center pt-16 md:pt-36 px-4'>
            <h2 className="text-[36px] md:text-[48px] lg:text-[60px] xl:text-[72px] 2xl:text-[96px] font-extrabold text-center">Share Your Bookmarks with Everyone</h2>
            <h5 className="text-[18px] md:text-[21px] lg:text-[24px] xl:text-[27px] 2xl:text-[36px] font-extralight text-center md:w-2/4">You can now share your favorite bookmarks with everyone</h5>
            <button onClick={openApp} className='rounded-lg bg-cornflower font-outfit font-semibold text-white px-5 py-3 mt-8 md:mt-16 transition-transform duration-300 hover:border-cornflower hover:text-cornflower hover:border-2 hover:bg-transparent'>🔥 Create Free Account</button>
            <div className='h-20' />
            <div className="relative w-full md:w-[1024px] h-[240px] md:h-[480px]">
                <div className="absolute inset-0 flex items-center justify-center px-2 md:px-10">
                    <button><img src={thumbnail} alt="Video Thumbnail" className='w-full md:w-auto' /></button>
                </div>
                <div className="absolute inset-0 flex items-end justify-between">
                    <div />
                    <img className="hidden md:flex items-center justify-center" src={secondSticker} width={250} />
                </div>
                <div className="absolute inset-0 flex items-center justify-end md:justify-between">
                    <img className="hidden md:flex items-center justify-center" src={firstSticker} width={250} />
                    <div className='w-[100px] md:w-[250px]' />
                </div>
            </div>
            <div className='md:h-20' />
            <p className='pt-12 pb-10 font-light text-[16px] md:text-[20px] lg:text-[23px] xl:text-[26px] 2xl:text-[32px]'>Supporting All Chromium-Based Browsers</p>
            <div className='flex flex-wrap justify-around items-center w-full md:w-4/5'>
                <img src={arcBrowser} className='w-[40px] h-[40px] md:w-[60px] md:h-[50px] 2xl:w-[100px] 2xl:h-[90px] mb-4 md:mb-0' />
                <img src={chromeBrowser} className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] 2xl:w-[90px] 2xl:h-[90px] mb-4 md:mb-0' />
                <img src={edgeBrowser} className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] 2xl:w-[90px] 2xl:h-[90px] mb-4 md:mb-0' />
                <img src={operaBrowser} className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] 2xl:w-[90px] 2xl:h-[90px] mb-4 md:mb-0' />
                <img src={duckDuckGoBrowser} className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] 2xl:w-[90px] 2xl:h-[90px] mb-4 md:mb-0' />
                <img src={puffinBrowser} className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] 2xl:w-[90px] 2xl:h-[90px] mb-4 md:mb-0' />
                <img src={ucBrowser} className='w-[40px] h-[40px] md:w-[50px] md:h-[50px] 2xl:w-[90px] 2xl:h-[90px] mb-4 md:mb-0' />
            </div>
            <div className='h-16 md:h-32' />
            <h2 className="text-[40px] md:text-[53px] font-extrabold text-center pb-5" id='features'>Share Pages You Like with Beemarks</h2>
            <p className='font-light text-center px-4 md:px-0 2xl:text-[28px]'>The bookmarks that everyone forgets and you never look back will be much more valuable now</p>
            <div className='h-20' />
            <div className='flex flex-col md:flex-row justify-between items-center w-full md:w-4/5'>
                <img src={cat} width={60} className="mb-4 md:mb-0" />
                <div />
            </div>
            <div className='flex flex-col md:flex-row items-stretch justify-between w-full md:w-4/5'>
                <div className='bg-beautifulDream w-full md:w-3/5 rounded-xl mb-4 md:mb-0'>
                    <img src={blueBoxContent} alt="Blue Box Content" className='w-full' />
                </div>
                <div className='w-0 md:w-5' />
                <div className='flex flex-col bg-brilliantWhite w-full md:w-2/5 rounded-xl p-5'>
                    <h4 className='text-[24px] md:text-[26px] lg:text-[28px] xl:text-[30px] 2xl:text-[48px] font-bold'>Beemark <br /> Your Favorite Websites</h4>
                    <p className='font-light 2xl:text-[28px]'>
                        Save your favorite pages with a free google plugin and share them with everyone. Your followers will be able to see these posts instantly and vote for free with BeeMark
                    </p>
                </div>
            </div>
            <div className='h-5' />
            <div className='flex flex-col md:flex-row items-stretch justify-between w-full md:w-4/5'>
                <div className='flex flex-col bg-whiteGloss w-full md:w-2/5 rounded-xl px-5 py-6 mb-4 md:mb-0'>
                    <h4 className='text-[24px] 2xl:text-[48px]  md:text-[30px] font-bold'>Customize Your <br /> Page From The Dashboard</h4>
                    <p className='font-light 2xl:text-[28px]'>
                        You can customize your own created page through the panel and add your social media accounts. If you want, you can fill the special banner fields as you wish. You can also use your own url domain name for free
                    </p>
                </div>
                <div className='w-0 md:w-5' />
                <div className='flex flex-col justify-center bg-wepeep w-full md:w-3/5 rounded-xl'>
                    <img src={redBoxContent} alt="Blue Box Content" className='w-full' />
                </div>
            </div>

            <div className='h-10 md:h-36' />
            <div className='relative w-full md:w-5/6 h-[300px]'>
                <div className='absolute inset-0 flex items-center justify-center'><img src={reviewBackgroundImage} className='hidden md:block' alt="" /></div>
                <h3 className='absolute inset-0 flex items-center md:items-start justify-center md:justify-between text-[20px] text-center md:text-[25px] lg:text-[30px] xl:text-[35px] 2xl:text-[48px] md:text-start font-semibold mt-10 ml'>You Can Check Out The Page<br />Created With Beemarks Now!  </h3>
                <div className='absolute inset-0 flex items-end justify-center md:justify-between md:mb-10 md:ml-5'>
                    <button onClick={openExample} className='rounded-lg bg-cornflower font-outfit font-semibold text-white px-5 py-3 mt-16 transition-transform duration-300 hover:border-cornflower hover:text-cornflower hover:border-2 hover:bg-transparent'>
                        <div className='flex flex-row space-x-6'>
                            <p >Check out Example</p>
                            <img src={whiteArrow} alt="Image 1" />
                        </div>
                    </button>
                </div>
            </div>
            <div className='h-20 md:h-36' />
            <h4 className='font-bold text-[30px] md:text-[32px] lg:text-[36px] xl:text-[42px] 2xl:text-[52px] text-center' id='faq'> Frequently Asked Questions</h4>
            <div className='h-4' />
            <p className='w-full md:w-2/3 2xl:w-1/2 2xl:text-[24px] text-center font-light'>We are a team of strategists, designers, communicators, researchers. We aim to develop a higher quality product for you.</p>
            <div className='h-12' />
            <FAQ />
            <div className='h-16' />

        </div>
    )
}

export default Features
