import React from 'react';
import logo from '../assets/images/other/logo_horizontal.png';

const PrivacyPolicy = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="flex-col w-2/3 space-y-5">
                <div className="flex flex-row items-center justify-between">
                    <h1 className="font-extrabold text-[30px]">Privacy Policy</h1>
                    <a href="/"><img src={logo} className="py-5 w-[200px]" /></a>
                </div>
                <p>
                    Welcome to Beemarks! We value your privacy and are committed to protecting your personal information. This
                    Privacy Policy explains how we collect, use, and share information when you use our Chrome extension.
                </p>

                <h2 className='font-bold text-[20px]'>Information We Collect</h2>
                <p>
                    Beemarks does not collect any personal information from users. The extension operates entirely within your
                    browser and does not send any data to external servers. All bookmarks and data related to your usage of
                    Beemarks are stored locally on your device.
                </p>

                <h2 className='font-bold text-[20px]'>How We Use Your Information</h2>
                <p>
                    Since Beemarks does not collect any personal information, we do not use, store, or share any data related to
                    your usage of the extension. Your bookmarks and any other data managed by the extension remain private and
                    under your control.
                </p>

                <h2 className='font-bold text-[20px]'>Sharing Your Information</h2>
                <p>
                    Beemarks does not share any information with third parties. Any bookmarks you choose to share are done
                    voluntarily by you, and Beemarks does not transmit this information to any external parties.
                </p>

                <h2 className='font-bold text-[20px]'>Data Security</h2>
                <p>
                    We are committed to ensuring the security of your data. Since all data is stored locally on your device, you are
                    in full control of its security. We recommend using best practices for device security to protect your bookmarks
                    and other personal data.
                </p>

                <h2 className='font-bold text-[20px]'>Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage
                    you to review this policy periodically.
                </p>

                <h2 className='font-bold text-[20px]'>Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy, please feel free to contact us at support@beemarks.com.
                </p>

                <p>
                    By using Beemarks, you agree to the terms outlined in this Privacy Policy.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
