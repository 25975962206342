import React, { useState } from 'react'
import logo from '../assets/images/other/logo_horizontal.png'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-scroll';
import openApp from '../utils/openApp';
const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <header className="text-textcolor p-4 font-outfit">
            <div className="container mx-auto flex flex-col md:flex-row lg:flex-row justify-between items-center">
                <a href="#"><img src={logo} alt="App Logo" className='w-[200px] md:w-[200px]' /></a>
                <div className='flex flex-grow mt-4 md:mt-0' />
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-2xl text-cornflower focus:outline-none">
                        {isOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>
                <nav className={`md:flex ${isOpen ? 'flex' : 'hidden'} flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 items-center`}>
                    <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 items-center">
                        <li><Link to='features' href='features' smooth={true} duration={500} className="hover:text-cornflower hover:text-[17px]">Features</Link></li>
                        <li><Link to="faq" href='faq' smooth={true} duration={500} className="hover:text-cornflower hover:text-[17px]">FAQ</Link></li>
                        <li><button onClick={openApp} className='border-2 border-cornflower text-cornflower text-[16px] py-2 px-8 transition-colors duration-300 rounded-lg hover:text-[17px]'>Use Now</button></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header
