import React from 'react';
import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';

function App() {
  return <Router>
    <Routes>
      <Route path="/:username" element={<Profile />} />
      <Route path='/' element={<LandingPage />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
    </Routes>
  </Router>;
}

export default App;
