import React from 'react'
import logo from '../assets/images/other/logo_horizontal.png'
import { Link } from 'react-scroll'
import openApp from '../utils/openApp';

const Footer = () => {
    return (
        <div className='flex flex-col w-full bg-[#E8EAFD] h-1/5 space-y-3 px-10 md:px-64'>
            <div className="container mx-auto flex flex-row justify-between items-center p-7">
                <a href="#"><img src={logo} alt="App Logo" className='w-[150px]' /></a>
                <div className='flex flex-grow' />
                <nav className='hidden md:block'>
                    <ul className="flex flex-row space-x-10 items-center">
                        <li><Link smooth={true} to="features" href='features' duration={500} className="text-[14px] hover:text-cornflower hover:text-[16px]">Features</Link></li>
                        <li><Link smooth={true} to="faq" href='faq' duration={500} className="text-[14px] hover:text-cornflower hover:text-[16px]">FAQ</Link></li>
                        <li><button onClick={openApp} className='border-2 border-cornflower text-cornflower text-[14px] py-2 px-1 transition-colors duration-300 rounded-lg hover:text-[14px]'>Use Now</button></li>
                    </ul>
                </nav>
            </div>
            <p className='text-[16px] font-light text-center md:text-start'>The site was made by <a className='text-cornflower underline' href='https://beemarks.app/beratkurt'>@beratkurt</a> and <a className='text-cornflower underline' href='https://beemarks.app/muhammetcalis'>@muhammetcalis</a></p>
        </div>
    )
}

export default Footer