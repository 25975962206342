import React, { useState } from 'react';
import plus from '../assets/images/vector/plus.svg';
import minus from '../assets/images/vector/minus.svg';

interface FAQItemProps {
    question: string;
    answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className="w-full m-2 p-2 border-2 rounded-lg px-5 border-[#D8E3FB]">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between items-center w-full py-4 text-left"
            >
                <span className="text-gray-800 2xl:text-[24px] pr-10">{question}</span>
                <img src={isOpen ? minus : plus} alt="" width={15} />
            </button>
            {isOpen && <p className="py-4 text-gray-600 font-light text-[15px] 2xl:text-[20px]">{answer}</p>}
        </div>
    );
};

interface FAQItem {
    question: string;
    answer: string;
}

const FAQ: React.FC = () => {
    const faqItems: FAQItem[] = [
        {
            question: 'How this site is different from others in market?',
            answer: 'Beemarks allows you to save your bookmarks completely free of charge. Moreover, you can share these bookmarks with your friends or followers.'
        },
        {
            question: 'What other themes do you have?',
            answer: 'Sorry Beemarks, currently only has one theme.'
        },
        {
            question: 'What kind of data does Beemarks collect?',
            answer: 'Beemarks requests personal data from users (name, email, biography, profession). It does not collect any other browser data other than the sites marked by the user..'
        },
        {
            question: 'Is it possible to get a discount?',
            answer: 'No discount needed. Beemarks is now completely FREE!'
        },
        {
            question: 'How does Beemarks make money?',
            answer: 'Beemarks is currently not profitable. We plan to create a more customizable structure with paid features in the future.'
        },
        {
            question: 'How do I use this?',
            answer: 'To use Beemarks, you can sign up and create your own page. Then, you can install the Chrome extension and share your bookmarks.'
        }
    ];
    var faqItemsFirstPart = faqItems.slice(0, 3);
    var faqItemsSecondPart = faqItems.slice(3, 6);

    return (
        <div className="w-full 2xl:w-3/4 flex flex-col sm:flex-row space-x-2">
            <div className='flex flex-col w-full'>
                {faqItemsFirstPart.map((item, index) => (
                    <FAQItem key={index} question={item.question} answer={item.answer} />
                ))}
            </div>
            <div className='flex flex-col w-full '>
                {faqItemsSecondPart.map((item, index) => (
                    <FAQItem key={index} question={item.question} answer={item.answer} />
                ))}
            </div>
        </div>
    );
};

export default FAQ;
